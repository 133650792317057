const policyLinks = [
  {
    path: "/privacy-policy",
    label: "Politika privatnosti",
  },
  {
    path: "/cookie-policy",
    label: "Politika kolačića",
  },
  {
    path: "/terms-of-service",
    label: "Prava i obaveze",
  },
];

export default policyLinks;
